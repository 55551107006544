import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

import { NotificationBannerMessageModel } from '../shared/notification-banner/notification-banner.component.model';

export enum BannerType {
  ABSENCE_IN_DAYS = 'absence-in-days',
  TIMESHEET_STATEMENTS = 'timesheet-statements',
  PUBLIC_HOLIDAY_GROUP_PROMOTION = 'public-holiday-group-promotion',
}
export interface BannerAlternative {
  applicableTo: string[];
  message: NotificationBannerMessageModel;
}

export interface MainBannerMessage extends NotificationBannerMessageModel {
  onlyApplicableTo?: string[];
  alternatives?: BannerAlternative[];
}

export const bannerMessages: Record<BannerType, MainBannerMessage> = {
  [BannerType.ABSENCE_IN_DAYS]: {
    title: _('New in Shiftbase: Absence in days'),
    emoji: '🎉 ',
    innerHTML: _(
      'Introducing our latest update: Absence in days! Easily manage time off requests with our new feature. Connect absence types to a time off balance, allowing full and half-day requests. Customize to fit your needs, with greater control over employee time off. If you want to learn how to migrate to this new feature, click on Learn more.',
    ),
    externalUrl: 'absence-in-days',
    redirectLink: '/account/settings/modules/absence',
  },
  [BannerType.TIMESHEET_STATEMENTS]: {
    title: _('New in Shiftbase: Export timesheet statements'),
    emoji: '🎉 ',
    innerHTML: _(
      "Exciting news: Export timesheets! Print your employees' timesheets with all the important details - overtime, absences and more. Get a comprehensive overview of your employees' hours. Try it now and experience an improved user experience!",
    ),
    externalUrl: 'printing-timesheet',
    redirectLink: '/timesheet/employee/week',
    onlyApplicableTo: ['DE', 'CH', 'AT'],
  },
  [BannerType.PUBLIC_HOLIDAY_GROUP_PROMOTION]: {
    title: _('Planning for holidays? Automate time off'),
    emoji: '🎉 ',
    innerHTML: _(
      'Set up public holiday groups now (including by department) to ensure accurate schedules and calculations, and reduce manual admin during the festive season',
    ),
    externalUrl: 'public-holidays',
    redirectLink: '/account/settings/modules/employees/holiday-groups',
    redirectLinkText: _('Set Up Public Holidays'),
  },
};

import { OverlayRef } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { Features } from '@app/enums';
import { FeatureFlagPipe } from '@app/pipes/feature-flag.pipe';
import { PlanType, SubscriptionCheckType, SubscriptionModel } from '@app/reducers/account/account-subscription.model';
import { AccountModel } from '@app/reducers/account/account.model';
import { AccountService, getAccount, getAccountState } from '@app/reducers/account/account.service';
import { UserGuideType } from '@app/reducers/orm/user-guide';
import { OnboardingChecklistService } from '@app/shared/onboarding/onboarding-checklist.service';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipModule } from '@sb/tooltip';
import { BadgeComponent, PopoverComponent, PopoverModule, PopoverService } from '@sb/ui';
import { combineLatest, filter, Observable, Subscription, take } from 'rxjs';

import { getPermissionState, hasPermission } from '../../../../app/reducers/auth/permission.helper';
import { EmployeeModel } from '../../../../app/reducers/orm/employee/employee.model';
import { getAuthenticatedUser } from '../../../../app/reducers/orm/employee/employee.service';
import { SidebarComponent } from '../sidebar/sidebar.component';
import { SupervisorRequestsSidebarComponent } from '../sidebar/supervisor-requests-sidebar/supervisor-requests-sidebar.component';
import { AppState } from './../../../reducers/index';
import { MyRequestsSidebarComponent } from './../sidebar/my-requests-sidebar/my-requests-sidebar.component';
import { SBHeaderAvatarComponent } from './buttons/sb-header-avatar.component';
import { SBHeaderHamburgerComponent } from './buttons/sb-header-hamburger.component';
import { SBHeaderIconComponent } from './buttons/sb-header-icon.component';
import { SBHeaderUpgradeButtonComponent } from './buttons/sb-header-upgrade.component';
import { getMyOpenRequestsCounterWithoutRejections, getSupervisorOpenRequestsCounter } from './sb-header.selectors';
import { SBHeaderService } from './sb-header.service';
import { SBNavComponent } from './sb-nav/sb-nav.component';
import { SBProfileDropdownComponent } from './sb-profile-dropdown/sb-profile-dropdown';

@Component({
  selector: 'sb-header',
  templateUrl: 'sb-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    SBNavComponent,
    RouterModule,
    SBHeaderIconComponent,
    TranslateModule,
    SBHeaderAvatarComponent,
    SidebarComponent,
    SupervisorRequestsSidebarComponent,
    MyRequestsSidebarComponent,
    SBHeaderHamburgerComponent,
    SBProfileDropdownComponent,
    SBHeaderUpgradeButtonComponent,
    BadgeComponent,
    TooltipModule,
    FeatureFlagPipe,
    PopoverModule,
  ],
  providers: [PopoverService],
})
export class SBHeaderComponent implements OnInit, OnDestroy {
  @ViewChild('supervisorRequestsSidebar', { static: true })
  public supervisorRequestsSidebar: SidebarComponent;
  @ViewChild('myRequestsSidebar', { static: true })
  public myRequestsSidebar: SidebarComponent;

  @ViewChild('onboardingChecklistPopover', { static: true })
  public onboardingChecklistPopover: TemplateRef<PopoverComponent>;

  @ViewChild('helpCenterButton', { static: true, read: ElementRef })
  public helpCenterButton: ElementRef<HTMLAnchorElement>;

  public popoverRef: OverlayRef;
  private subscription = new Subscription();
  public authUser$: Observable<EmployeeModel>;

  public account: AccountModel;
  public hasManageAccountPermission: boolean;
  public hasAppCenterPermission: boolean;
  public totalNotificationCount: number;

  public planType = PlanType;
  public planCheckType = SubscriptionCheckType;
  public readonly Features = Features;

  public currentSubscription: SubscriptionModel;

  public constructor(
    private store: Store<AppState>,
    public headerService: SBHeaderService,
    private accountService: AccountService,
    private router: Router,
    private onboardingChecklistService: OnboardingChecklistService,
    private popoverService: PopoverService,
    private cd: ChangeDetectorRef,
    private viewContainerRef: ViewContainerRef,
  ) {}

  public ngOnInit() {
    this.authUser$ = this.store.select(getAuthenticatedUser);

    this.subscription.add(
      combineLatest([
        this.store.select(getPermissionState),
        this.store.select(getMyOpenRequestsCounterWithoutRejections),
        this.store.select(getSupervisorOpenRequestsCounter),
        this.store.select(getAccount),
        this.store.select(getAccountState),
      ]).subscribe(([permissionState, myOpenRequests, supervisorRequests, account, accountState]) => {
        this.account = account;
        const hasSupervisorPerms = hasPermission(
          {
            permissions: ['Approve absentee', 'Approve exchange'],
            userId: 'me',
            departments: 'any',
          },
          permissionState,
        );

        this.totalNotificationCount = myOpenRequests;
        if (hasSupervisorPerms) {
          this.totalNotificationCount += supervisorRequests;
        }

        this.hasManageAccountPermission = hasPermission(
          {
            permissions: ['Manage account'],
            userId: 'me',
            departments: 'any',
          },
          permissionState,
        );

        this.hasAppCenterPermission = hasPermission(
          {
            permissions: ['Manage API tokens', 'Manage payroll apps', 'Manage non payroll apps'],
            userId: 'me',
            departments: 'any',
          },
          permissionState,
        );
        this.currentSubscription = accountState.subscription;
      }),
    );

    this.subscription.add(
      this.headerService.supervisorRequestsSidebarOpen$.subscribe((open) => {
        if (open) {
          this.supervisorRequestsSidebar.open();
          return;
        }
        this.supervisorRequestsSidebar.close();
      }),
    );

    this.subscription.add(
      this.headerService.myRequestsSidebarOpen$.subscribe((open) => {
        if (open) {
          this.myRequestsSidebar.open();
          return;
        }
        this.myRequestsSidebar.close();
      }),
    );

    this.subscription.add(
      this.onboardingChecklistService.checklistDismissed$
        .pipe(filter((type) => type === UserGuideType.SELF_ONBOARDING_CHECKLIST))
        .subscribe(() => {
          const hasBeenDismissedBefore = localStorage.getItem('sb-self-onboarding-checklist-dismissed');
          if (!hasBeenDismissedBefore) {
            localStorage.setItem('sb-self-onboarding-checklist-dismissed', 'true');
            this.popoverRef = this.popoverService.openPopover(
              this.onboardingChecklistPopover,
              this.helpCenterButton,
              this.viewContainerRef,
              'bottom',
              undefined,
              false,
            );
            this.cd.detectChanges();
          }
        }),
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public openBetaDialog() {
    void this.accountService.openBetaDialog(this.hasManageAccountPermission).pipe(take(1));
  }

  public openHelpCenter() {
    this.router.navigate(['', { outlets: { modal: ['support'] } }]);
    if (this.popoverRef) {
      this.popoverService.closePopover(this.popoverRef);
      this.popoverRef = undefined;
    }
  }
}

import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';

import { getAuthenticatedUserId } from '../../../reducers/auth/auth.service';
import { AppState } from '../../../reducers/index';
import { getSelectedMyScheduleDate } from '../../../reducers/page-filters/page-filters.helper';
import { parseDate } from '../../../shared/date.helper';
import { lazySelect } from '../../../shared/lazy-select.observable';

@Component({
  selector: 'my-schedule',
  template: `
    <personal-schedule
      [date]="date | async"
      [employeeId]="employeeId$ | async"
      (changeDateEmitter)="changeDate($event)"
    ></personal-schedule>
  `,
})
export class MyScheduleComponent {
  public employeeId$ = this.store.pipe(lazySelect(getAuthenticatedUserId));

  public date = this.store.pipe(
    lazySelect(getSelectedMyScheduleDate),
    map((date) => parseDate(date)),
  );

  public constructor(
    private store: Store<AppState>,
    private router: Router,
  ) {}

  public changeDate(date: string): void {
    this.router.navigate(['dashboard/my-schedule', { date }]);
  }
}

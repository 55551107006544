import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotificationBannerComponent } from '@app/+authenticated/shared/notification-banner/notification-banner.component';
import { Features } from '@app/enums';
import { FeatureFlagPipe } from '@app/pipes/feature-flag.pipe';
import { KnowledgeBaseArticleLinkModule } from '@app/pipes/knowledge-base-article-link.module';
import { KnowledgeBaseArticleLinkPipe } from '@app/pipes/knowledge-base-article-link.pipe';
import { FeatureFlagGuard } from '@app/shared/guards/feature-flag/feature-flag.guard';
import { SidebarComponent } from '@app/shared/sb-lib/sidebar/sidebar.component';
import { VerificationBannerComponent } from '@app/shared/verification-banner/verification-banner.component';
import {
  BubbleNotificationComponent,
  ChecklistComponent,
  ChecklistMinimizedComponent,
  IconComponent,
  LegacyChecklistComponent,
} from '@sb/ui';
import { DialogService } from 'primeng/dynamicdialog';

import { UniversalLinkComponent } from '../+anonymous/universal/universal-link.component';
import { SubscriptionPlanGuard } from '../shared/subscription-plan/subscription-plan.guard';
import { AuthenticatedGuard } from '../startup/authenticated-guard.service';
import { FilesComponent } from './+files/files.component';
import { PlanType } from './+reports/shared/subscriptions/subscription.model';
import { ScheduleSidebarService } from './+schedule/employee/schedule-employee-n-period/schedule-sidebar.service';
import { AuthenticatedComponent } from './authenticated.component';
import { DashboardSidebarComponent } from './dashboard/dashboard-sidebar/dashboard-sidebar.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { MyAbsenceComponent } from './dashboard/my-absence/my-absence.component';
import { MyDashboardComponent } from './dashboard/my-dashboard/my-dashboard.component';
import { MyDashboardModule } from './dashboard/my-dashboard/my-dashboard.module';
import { MyHoursComponent } from './dashboard/my-hours/my-hours.component';
import { MyPlusMinusComponent } from './dashboard/my-plus-minus/my-plus-minus.component';
import { MyScheduleDateResolver } from './dashboard/my-schedule/my-schedule-date.resolver';
import { MyScheduleComponent } from './dashboard/my-schedule/my-schedule.component';
import { LogoutComponent } from './logout.component';
import { PermissionGuard } from './permission-guard.service';
import { LocaleService } from './shared/locale/locale.service';
import { NavigationItemComponent } from './shared/navigation/navigation-item.component';
import { NavigationComponent } from './shared/navigation/navigation.component';
import { SBHeaderComponent } from './shared/sb-header/sb-header.component';
import { SharedAuthModule } from './shared/shared-auth.module';
import { MyRequestsSidebarComponent } from './shared/sidebar/my-requests-sidebar/my-requests-sidebar.component';
import { OpenShiftsComponent } from './shared/sidebar/my-requests-sidebar/open-shifts.component';
import { SupervisorRequestsSidebarComponent } from './shared/sidebar/supervisor-requests-sidebar/supervisor-requests-sidebar.component';
import { DashboardMenuRoutes } from './shared/sub-menu/dashboard-menu.routes';

const routes: Routes = [
  {
    path: '',
    component: AuthenticatedComponent,
    canActivate: [AuthenticatedGuard],
    canActivateChild: [PermissionGuard],
    children: [
      ...DashboardMenuRoutes,
      {
        path: 'news',
        loadChildren: () => import('./+news/news.module').then((m) => m.NewsModule),
      },
      {
        path: 'files',
        component: FilesComponent,
        data: {
          permission: ['Manage account'],
          subscriptionPlan: PlanType.BASIC,
        },
        canActivate: [PermissionGuard, SubscriptionPlanGuard],
      },

      {
        path: 'my-account',
        loadChildren: () => import('./+my-account/my-account.module').then((m) => m.MyAccountModule),
      },
      {
        path: 'account',
        loadChildren: () => import('./+account/account.module').then((m) => m.AccountModule),
      },
      {
        path: 'employees',
        loadChildren: () => import('./+employees/employees.module').then((m) => m.EmployeesModule),
      },
      {
        path: 'schedule',
        loadChildren: () => import('./+schedule/schedule.module').then((m) => m.ScheduleModule),
      },
      {
        path: 'timesheet',
        loadChildren: () => import('./+timesheet/timesheet.module').then((m) => m.TimesheetModule),
        data: {
          permission: ['View all timesheets', 'View own timesheet'],
        },
        canActivate: [PermissionGuard],
      },
      {
        path: 'diary',
        loadChildren: () => import('./+diary/diary.module').then((m) => m.DiaryModule),
        data: {
          subscriptionPlan: PlanType.BASIC,
        },
        canActivate: [SubscriptionPlanGuard],
      },
      {
        path: 'insights',
        loadComponent: () => import('./insights/insights-page.component').then((m) => m.InsightsComponent),
        data: {
          subscriptionPlan: PlanType.BASIC,
          featureFlag: Features.TMP_INSIGHTS_PAGE,
          permission: ['View log'],
        },
        canActivate: [SubscriptionPlanGuard, FeatureFlagGuard, PermissionGuard],
      },
      {
        path: 'reports',
        loadChildren: () => import('./+reports/reports.module').then((m) => m.ReportsModule),
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'dashboard/my-overview',
      },
      { path: 'universal/:type', component: UniversalLinkComponent },
      { path: 'universal/:type/:id', component: UniversalLinkComponent },
    ],
  },
  {
    path: 'logout',
    component: LogoutComponent,
    canActivate: [AuthenticatedGuard],
  },
  {
    path: 'mobile-welcome',
    loadComponent: () => import('./mobile-welcome/mobile-welcome.component').then((m) => m.MobileWelcomeComponent),
  },
];

@NgModule({
  imports: [
    SharedAuthModule,
    MyDashboardModule,
    RouterModule.forChild(routes),
    SBHeaderComponent,
    SupervisorRequestsSidebarComponent,
    MyRequestsSidebarComponent,
    OpenShiftsComponent,
    KnowledgeBaseArticleLinkModule,
    NotificationBannerComponent,
    VerificationBannerComponent,
    LegacyChecklistComponent,
    ChecklistComponent,
    ChecklistMinimizedComponent,
    BubbleNotificationComponent,
    FeatureFlagPipe,
    IconComponent,
    SidebarComponent,
  ],
  providers: [
    MyScheduleDateResolver,
    LocaleService,
    ScheduleSidebarService,
    KnowledgeBaseArticleLinkPipe,
    DialogService,
    NotificationBannerComponent,
  ],
  declarations: [
    AuthenticatedComponent,
    LogoutComponent,
    NavigationComponent,
    NavigationItemComponent,
    DashboardComponent,
    DashboardSidebarComponent,
    MyDashboardComponent,
    MyHoursComponent,
    MyAbsenceComponent,
    MyPlusMinusComponent,
    MyScheduleComponent,
    UniversalLinkComponent,
  ],
})
export class AuthenticatedModule {}

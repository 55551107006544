import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BadgeComponent } from '@sb/ui';

import { IconComponent } from '../../../icon.component';

@Component({
  selector: 'sb-nav-item',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [IconComponent, CommonModule, RouterModule, BadgeComponent],
  template: `
    <a
      [routerLink]="locked ? null : link"
      [routerLinkActive]="locked ? '' : 'active'"
      #routerLink="routerLinkActive"
      class="group flex w-full cursor-pointer items-center rounded-[5px] px-3.5 py-2.5 font-semibold text-grey-800 hover:bg-grey-100"
      [ngClass]="{
        'bg-primary-50': routerLink.isActive,
        'hover:bg-primary-50': routerLink.isActive,
        'bg-grey-100': locked,
        'h-[4rem]': isMobile,
        'text-base': !isMobile,
        'text-lg': isMobile
      }"
      (click)="clicked()"
    >
      <span
        class="flex select-none items-center gap-2 group-hover:text-grey-500"
        [ngClass]="{
          'text-grey-400': locked,
          'text-primary-500': routerLink.isActive,
          'group-hover:text-primary-500': routerLink.isActive
        }"
      >
        <icon svgIcon="lock" *ngIf="locked" class="mr-2"></icon>
        {{ label }}
        @if (beta) {
          <sb-badge [color]="'primary'">BETA</sb-badge>
        }
      </span>
    </a>
  `,
})
export class NavigationItemComponent {
  @Input()
  public link: string[] | string;
  @Input()
  public label: string;
  @Input()
  public locked: boolean;
  @Input()
  public isMobile: boolean;
  @Input()
  public beta: boolean;
  @Output()
  public lockClick = new EventEmitter();

  public clicked() {
    if (!this.locked) {
      return;
    }

    this.lockClick.emit();
  }
}

import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Features } from '@app/enums';
import { FeatureFlagPipe } from '@app/pipes/feature-flag.pipe';
import { FeatureService } from '@app/startup/feature.service';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { DialogService } from 'primeng/dynamicdialog';
import { combineLatest, Subscription } from 'rxjs';

import { AppState } from '../../../../reducers';
import { getAccountSubscription } from '../../../../reducers/account/account.service';
import { getPageParamsState } from '../../../../reducers/page-params-state/page-params.service';
import { SbPlanLockDialogComponent } from '../../../../shared/sb-lib/dynamic-dialogs/plan-lock/sb-plan-lock-dialog.component';
import {
  LockType,
  planLockDialogConfig,
} from '../../../../shared/sb-lib/dynamic-dialogs/plan-lock/sb-plan-lock-dialog.model';
import { SbPlanDialogService } from '../../../../shared/sb-lib/dynamic-dialogs/plan-lock/sb-plan-lock-dialog.service';
import { hasAtMostSubscriptionPlan } from '../../../../shared/subscription-plan/subscription-plan.directive';
import { PlanType } from '../../../+reports/shared/subscriptions/subscription.model';
import { PermissionDirective } from '../../permission.directive';
import { NavigationItemComponent } from './sb-nav-item/sb-nav-item.component';

@Component({
  selector: 'sb-nav',
  templateUrl: 'sb-nav.component.html',
  providers: [DialogService],
  standalone: true,
  imports: [NavigationItemComponent, TranslateModule, PermissionDirective, NgClass, NgIf, AsyncPipe, FeatureFlagPipe],
})
export class SBNavComponent implements OnInit, OnDestroy {
  @Input()
  public isMobile = false;

  @Output()
  public itemClicked: EventEmitter<any> = new EventEmitter<any>();

  public scheduleLink: string | any[] = '/schedule/employee/week';
  public timesheetLink: string | any[] = '/timesheet/team/day';

  public planType = PlanType;
  public lockType = LockType;
  public hasAtMostFree: boolean;
  public features = Features;

  private dataSubs = new Subscription();

  public constructor(
    private store: Store<AppState>,
    private dialogService: DialogService,
    public sbPlanDialogService: SbPlanDialogService,
    public featureService: FeatureService,
  ) {}

  public onClick() {
    this.itemClicked.emit();
  }

  public ngOnInit(): void {
    this.dataSubs.add(
      combineLatest([this.store.select(getPageParamsState), this.store.select(getAccountSubscription)]).subscribe({
        next: ([pageParams, accountSubscription]) => {
          this.hasAtMostFree = hasAtMostSubscriptionPlan(PlanType.FREE, accountSubscription);
          this.scheduleLink = [
            '/schedule',
            pageParams.schedule.mode,
            pageParams.schedule.period,
            { date: pageParams.schedule.date },
          ];
          this.timesheetLink = [
            '/timesheet',
            pageParams.timesheet.mode,
            pageParams.timesheet.period,
            { date: pageParams.timesheet.date },
          ];
        },
      }),
    );
  }

  public ngOnDestroy(): void {
    this.dataSubs.unsubscribe();
  }

  public openLockModal(lockType: LockType) {
    this.dialogService.open(SbPlanLockDialogComponent, {
      data: {
        lockType: lockType,
      },
      ...planLockDialogConfig,
    });
  }
}
